<template>
    <div class="google-map new-updated-design google-map-design">
        <vue-snotify></vue-snotify>
        <div class="row">

            <div class="col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('interactive-maps') }}</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <div class="google-map new-updated-design">
                            <div id="menu">
                                <div>
                                    <input id="satellite-v9" type="radio" v-model="default_map" @click="saveMapStyle('satellite-v9')"
                                    value="mapbox://styles/mapbox/satellite-v9"
                                    :checked="default_map === 'mapbox://styles/mapbox/satellite-v9'">
                                    <label class="map-label" for="satellite-v9">{{ $t('satellite') }}</label>
                                </div>
                                <div>   
                                    <input id="light-v10" class="ml-2" type="radio" v-model="default_map" @click="saveMapStyle('light-v10')"
                                    value="mapbox://styles/mapbox/light-v10"
                                    :checked="default_map === 'mapbox://styles/mapbox/light-v10'">
                                    <label class="map-label" for="light-v10">{{ $t('light') }}</label>
                                </div>
                                <div>
                                    <input id="dark-v10" type="radio" class="ml-2" v-model="default_map" @click="saveMapStyle('dark-v10')"
                                    value="mapbox://styles/mapbox/dark-v10" :checked="default_map === 'mapbox://styles/mapbox/dark-v10'">
                                    <label class="map-label" for="dark-v10">{{ $t('dark') }}</label>
                                    <!--            <input id="streets-v11" type="radio" v-model="default_map" @click="saveMapStyle('streets-v11')" value="streets">-->
                                    <!--            <label for="streets-v11">streets</label>-->
                                </div>
                                <div>
                                    <input id="outdoors-v11" type="radio" class="ml-2 m-ml" v-model="default_map"
                                    @click="saveMapStyle('outdoors-v11')"
                                    value="mapbox://styles/mapbox/outdoors-v11"
                                    :checked="default_map === 'mapbox://styles/mapbox/outdoors-v11'">
                                    <label class="map-label" for="outdoors-v11">{{ $t('outdoors') }}</label>
                                </div>
                                <div class="map-btns"> 
                                    <b-button class="ml-5 new-ml variant-orange" size="sm" @click="openSiderBar">{{ $t('total-category-counts') }}</b-button>
                                    <b-button v-if="mapLoaded" class="ml-5 new-ml variant-orange" size="sm" @click="openFilters">{{ $t('filters-btn') }}</b-button>
                                    <b-dropdown
                                        v-if="this.version_id === 2 && this.is_pro_sync === 1"
                                            class="btn-primary ml-2"
                                            variant="primary"
                                            size="sm"
                                            :text="$t('Project (Survey Name)')"
                                            v-model="selectedProjectId"
                                        >
                                        <!-- Add the "Select" option -->
                                        <b-dropdown-item
                                            :key="'select'"
                                            :active="selectedProjectId === 'select'"
                                            @click="onProjectClick(null)" 
                                        >
                                            {{ $t("All Data") }}
                                        </b-dropdown-item>

                                        <!-- Render the limited number of projects -->
                                        <div ref="dropdownMenu" style="max-height: 300px; overflow-y: auto;">
                                            <b-dropdown-item
                                                v-for="project in displayedProjects"
                                                :key="project.id"
                                                :active="project.id === selectedProjectId"
                                                @click="onProjectClick(project.id)"
                                            >
                                                {{ project.name }}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </div>
                                
                            </div>
                            
                            <div class="sidebar-counter">
                                <TotalCategoryCount  ref="sidebar" :countableQuestions="countableQuestions" :totalSpots="totalSpots" user_type="admin"/>
                            </div>
                            <div class="map-container" id="mapContainer">
                                <div style="display: none;" id="coordinates">
                                </div>
                            <!--    <button v-if="mapLoaded" class="filter-group" variant="info" size="sm" @click="openFilters">{{ $t('filters-btn') }}</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SpotDetailsModal ref="spotDetails"></SpotDetailsModal>
        <b-modal id="modal-clusters" :title="$t('cluster-spots')" scrollable size="md" :hide-footer="true">
            <ClusterSpotsModal :spotsData="spotsData" @openParentSpot="openAdminSpotDetail"></ClusterSpotsModal>
        </b-modal>
        <FiltersModal @applyFilter="applyFilter"  @resetMap="getClientAdminSpots" ref="filterComponent" user_type="admin"></FiltersModal>
    
    </div>
</template>
<script>
    import Vue from "vue";
    import mapboxgl from "mapbox-gl";
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import API from '@/api';
    import moment from 'moment';
    import ClusterSpotsModal from './ClusterSpotsModal.vue';
    import FiltersModal from "../citizen/FiltersModal.vue";
    import TotalCategoryCount from '../client/TotalCategoryCounts';
    import SpotDetailsModal from '../citizen/SpotDetailsModal.vue';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        props: ['countableQuestions', 'total_spots'],
        data() {
            return {
                version_id: null,
                is_pro_sync: null,
                routeTracks: [],
                accessToken: 'pk.eyJ1Ijoid2F0Y2hzcG90dGVyIiwiYSI6ImNsMmY0OTgxdzA3MW0zYm1jMmY5MGY5OG4ifQ.vEle7r52YhgPJ8D-MVlZ2A',
                spotData: [],
                map: [],
                features: [],
                mapFeatures: [],
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: window.location.href.split('#')[0],
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                },
                adminMapStyle: '',
                default_map: '',
                default_map_zoom: '',
                default_latitude: '',
                default_longitude: '',
                appImage: null,
                spotDetailImages: [],
                spotDetail: [],
                spotsData: [],
                // showBackButton:false,
                mapLoaded: false,
                totalSpots: 0,
                markerColor: '',
                projectOptions: [],
                selectedProjectId: null,
                displayedProjects: [], // Initially visible projects
                loading: false,
                currentPage: 1,
                perPage: 5 
            };
        },
        components: {
            ClusterSpotsModal,
            FiltersModal,
            TotalCategoryCount,
            SpotDetailsModal
        },
        beforeMount(){
            this.getNames();
            const storedProjectId = localStorage.getItem("selected_project_id");
            if (storedProjectId && storedProjectId !== "null") {
                this.selectedProjectId = JSON.parse(storedProjectId);
                this.getClientProjectSpots(this.selectedProjectId);
            } else {
                this.getClientAdminSpots();
            }
        },
        watch: {
            selectedProjectId(newProjectId) {
                if (this.map) {
                    if (this.map.getSource("spotsSource")) {
                        this.map.removeLayer("spotsLayer");
                        this.map.removeSource("spotsSource");
                    }
                    this.map.remove(); // Remove the map instance
                }

                if (newProjectId) {
                    this.getClientProjectSpots(newProjectId);
                } else {
                    this.getClientAdminSpots();
                }
            }
        },
        created() {
            this.$EventBus.$on("showAdminClusterSpots", (data) => {
                this.$root.$emit("bv::show::modal", "modal-clusters");
            });
            this.$EventBus.$on("reinitializeMap", (data) => {
                this.map.remove();
                this.getClientAdminSpots();
            });
        },
        mounted() {
            this.getAppSettings();
            // this.getClientAdminSpots();
        }, methods: {
            getClientAdminSpots() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientAdminSpots(
                    data,
                    data => {
                        const spot_dImage = data.default_image;
                        this.totalSpots = data.total_spots;

                        data.data.forEach(data => {
                            let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                            if (!exists) {
                                const d1 = {
                                    type: "Feature",
                                    properties: {
                                        id: data.id,
                                        title: (data.spot_type) ? data.spot_type.name : '',
                                        // spot_id: data.spot_type_id,
                                        spot_id: data.spot_id,
                                        description: (data.location) ? data.location.name : '',
                                        spotted_by: (data.spotter_user) ? data.spotter_user.name : '',
                                        spotted_username: (data.spotter_user) ? data.spotter_user.user_name : '',
                                        spot_date: this.formatDate(data.spot_date),
                                        iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                        markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [data.longitude, data.latitude]
                                    }
                                }
                                this.mapFeatures.push(d1);
                            }
                        })

                        // alert('working')

                        // if (navigator.geolocation) {
                        //     var self = this;
                        //     navigator.geolocation.getCurrentPosition(e => {
                        //         this.initMap([e.coords.longitude, e.coords.latitude])
                        //     }, function (error) {
                        //         if (error.code == error.PERMISSION_DENIED) {
                        //             // pop up dialog asking for location
                        //             self.initMap([self.default_longitude, self.default_latitude]);
                        //         }
                        //     })
                        // } else {
                            this.initMap([this.default_longitude, this.default_latitude]);
                        // }
                    },
                    err => {
                    }
                )
            },
            getClientProjectSpots(projectID) {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    project_id: projectID
                }
                API.getClientAdminSpots(
                    data,
                    data => {
                    this.mapFeatures = []; // Clear the mapFeatures array
                    const spot_dImage = data.default_image;
                    this.totalSpots = data.total_spots;
                    data.data.forEach(data => {
                        const d1 = {
                        type: "Feature",
                        properties: {
                            id: data.id,
                            title: (data.spot_type) ? data.spot_type.name : '',
                            spot_id: data.spot_id,
                            description: (data.location) ? data.location.name : '',
                            spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                            spotted_username: (data.spotter_user) ? data.spotter_user.user_name : '',
                            spot_date: this.formatDate(data.spot_date),
                            iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                            markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [data.longitude, data.latitude]
                        }
                        }
                        this.mapFeatures.push(d1);
                    })
                    if (this.mapFeatures.length > 0) {
                        const firstSpot = this.mapFeatures[0];
                        this.initMap([firstSpot.geometry.coordinates[0], firstSpot.geometry.coordinates[1]]);
                    }
                    },
                    err => {
                    // Handle error
                    }
                )
            },
            initMap(lngLat) {
                this.mapLoaded = false;
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: "mapContainer",
                    style: (this.default_map) ? this.default_map : 'mapbox://styles/mapbox/light-v10',
                    center: lngLat,
                    zoom: this.default_map_zoom,
                    maxZoom: 14,
                    attributionControl: false
                });
                // more initialization
                this.spotForm.longitude = lngLat[0];
                this.longitude = lngLat[0];
                this.spotForm.latitude = lngLat[1];
                this.latitude = lngLat[1];
                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(lngLat).addTo(this.map);
                var coordinates = document.getElementById('coordinates');
                coordinates.style.display = 'block';
                coordinates.innerHTML = `Lat: ${parseFloat(this.latitude).toFixed(6)}, Lng: ${parseFloat(this.longitude).toFixed(6)}`; 
                var ref = this;
                this.marker.on('dragend', (e) => {
                    var lngLat = e.target.getLngLat();
                    // this.$refs.marker.mapObject.getLatLng();
                    // ref.getLatLong(lngLat)
                    coordinates.innerHTML = `Lat: ${lngLat.lat.toFixed(6)}, Lng: ${lngLat.lng.toFixed(6)}`;
                })


                var loadFeatures = this.mapFeatures;
                const geojson = {
                    'type': 'FeatureCollection',
                    'features': this.mapFeatures
                };

                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, "top-right");
                this.map.addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                        enableHighAccuracy: true
                    },
                        trackUserLocation: true,
                        showUserHeading: true
                    })
                );


                this.map.on('load', () => {
                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }
                    this.map.addSource('earthquakes', {
                        type: 'geojson',
                        data: this.data2,
                        cluster: true,
                        clusterMaxZoom: 14,
                        clusterRadius: 50
                    });

                    this.map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#FF7F50',
                                100,
                                '#003366',
                                750,
                                '#ff414e'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });

                    this.map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12,
                            "text-allow-overlap" : true
                        },
                        paint: {
                            "text-color": "#ffffff"
                        }
                    });

                    // Fetch and add the route track data
                    const data = {
                        client_id: JSON.parse(localStorage.getItem('client_id')),
                    }
                    API.getTracksData(
                        data,
                        (data) => {
                            // Prepare the track data for the map
                            const trackCoordinates = data.map(track => [track.longitude, track.latitude]);

                            // Add a new source for the route tracks
                            this.map.addSource('routeTracks', {
                                type: 'geojson',
                                data: {
                                    type: 'FeatureCollection',
                                    features: [{
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: trackCoordinates
                                        },
                                        properties: {}
                                    }]
                                }
                            });

                            // Add a layer to display the route tracks with a curved, dotted style
                            this.map.addLayer({
                                id: 'route',
                                type: 'line',
                                source: 'routeTracks',
                                layout: {
                                    'line-cap': 'round',  // Rounded end for smoother curves
                                    'line-join': 'round'  // Rounded join for smoother corners
                                },
                                paint: {
                                    'line-color': '#888',  // Line color
                                    'line-width': 4,       // Line width
                                    'line-dasharray': [4, 4]  // Dash pattern: 4px dash, 4px space
                                }
                            });

                            // Add markers at each point along the route
                            trackCoordinates.forEach((coord, index) => {
                                const markerElement = document.createElement('div');
                                markerElement.style.backgroundColor = '#FF0000'; // Red color for the marker
                                markerElement.style.width = '20px';
                                markerElement.style.height = '20px';
                                markerElement.style.borderRadius = '50%'; // Make it circular
                                markerElement.style.border = '2px solid white'; // Add a white border for better visibility
                                
                                // Add marker to the map
                                new mapboxgl.Marker(markerElement)
                                    .setLngLat(coord)
                                    .setPopup(new mapboxgl.Popup({ offset: 25 }) // Add a popup
                                        .setHTML(`<p>Point ${index + 1}:<br>Lat: ${coord[1]}, Lng: ${coord[0]}</p>`)) // Popup content
                                    .addTo(this.map);
                            });
                        },
                        (error) => {
                            console.error('Error fetching route tracks:', error);
                        }
                    );

                    if(this.version_id === 2 || this.version_id === 3){
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                            if (error) throw error;
                            // Add the image to the map style.
                            this.map.addImage('cat', image,{
                                "sdf": "true"
                            });
                        });
                        let categoryColors = [];

                        // Populate categoryColors array with actual colors
                        this.mapFeatures.forEach((feature) => {
                            categoryColors.push(feature.properties.markerColor || '');
                        });

                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            layout: {
                                "icon-image": [
                                    "match", ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin", ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale", ["BS"], "baskingshark", ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-size": 1,
                                "icon-padding": 2,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            },
                            paint: {
                                "icon-color": [
                                    "get",
                                    "icon-color"
                                ],
                                "icon-halo-blur": 0,
                                "icon-halo-color": [
                                    "get",
                                    "icon-halo-color"
                                ],
                                "icon-halo-width": 4
                            }
                        });

                        // Set the GeoJSON source data with individual colors for each feature
                        const featuresWithColors = this.mapFeatures.map((feature, index) => {
                            return {
                                ...feature,
                                properties: {
                                    ...feature.properties,
                                    'icon-color': categoryColors[index] || '',
                                    'icon-halo-color': categoryColors[index] || ''
                                }
                            };
                        });

                        this.map.getSource('earthquakes').setData({
                            type: 'FeatureCollection',
                            features: featuresWithColors
                        });
                    }
                    else{
                        this.map.loadImage(
                            this.appImage,
                            (error, image) => {
                            if (error) throw error;
                            // Add the image to the map style.
                            this.map.addImage('cat', image);
                        });
                        this.map.addLayer({
                            id: 'unclustered-point',
                            type: 'symbol',
                            source: 'earthquakes', // reference the data source
                            filter: ['!', ['has', 'point_count']],
                            // layout: {
                            //     'icon-image': 'cat', // reference the image
                            //     'icon-size': 0.25
                            // }
                            // paint: {
                            //     "text-color": "#d21010",
                            //     "icon-halo-color": "#d21010",
                            //     "icon-halo-width": 2
                            // },
                            layout: {
                                "icon-image": [
                                    "match",
                                    ["get", "spot_id"],
                                    ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin",
                                    ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale",
                                    ["BS"], "baskingshark",
                                    ["UC", "UN", "OR", "SU"], "",
                                    "cat"
                                ],
                                "icon-padding": 2,
                                // 'icon-size': 0.05,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": {
                                    "base": 1,
                                    "stops": [
                                        [0, true],
                                        [12, true],
                                        [22, true]
                                    ]
                                },
                            },
                        });
                    }
                    this.map.on('click', 'clusters', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        var clusterId = features[0].properties.cluster_id;
                        var zoom = this.map.getZoom();
                        if(zoom < 14) {
                            this.map.getSource('earthquakes').getClusterExpansionZoom(
                                clusterId,
                                (err, zoom) => {
                                    if (err) return;
                                    this.map.easeTo({
                                        center: features[0].geometry.coordinates,
                                        zoom: zoom
                                    });
                                }
                            );
                        } else {
                            var point_count = e.features[0].properties.point_count;
                            var clusterSource = this.map.getSource("earthquakes");

                            clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                                this.spotsData = aFeatures;
                                this.spotsData.sort(function(a,b) {
                                    return new Date(b.properties.spot_date) - new Date(a.properties.spot_date);
                                });
                                this.openClusterSpotDetail(this.spotsData);
                            });
                        }
                    });

                    // if(this.version_id === 2 || this.version_id === 3){
                    //     this.mapFeatures.forEach((feature) =>{
                    //         this.map.on('click', `unclustered-point-${feature.properties.id}`, (e) => {
                    //             const coordinates = e.features[0].geometry.coordinates.slice();
                    //             const spot_type = e.features[0].properties.title;
                    //             const id = e.features[0].properties.id;
                    //             const spot_id = e.features[0].properties.spot_id;
                    //             const iconUrl = e.features[0].properties.iconUrl;
                    //             const spotted_by = e.features[0].properties.spotted_by;
                    //             const spotted_username = e.features[0].properties.spotted_username;
                    //             const spot_date = e.features[0].properties.spot_date;
                    //             const description = e.features[0].properties.description;
                    //             while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    //                 coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    //             }
                    //             // this.openSpotDetail(id,false);
                    //             this.$refs.spotDetails.openAdminSpotDetail(id, false);
                    //         });
                    //     });

                    //     this.map.on('mouseenter', 'clusters', () => {
                    //         this.map.getCanvas().style.cursor = 'pointer';
                    //     });
                    //     this.map.on('mouseleave', 'clusters', () => {
                    //         this.map.getCanvas().style.cursor = '';
                    //     });
                    // }
                    // else{
                        this.map.on('click', 'unclustered-point', (e) => {
                                const coordinates = e.features[0].geometry.coordinates.slice();
                                const spot_type = e.features[0].properties.title;
                                const id = e.features[0].properties.id;
                                const spot_id = e.features[0].properties.spot_id;
                                const iconUrl = e.features[0].properties.iconUrl;
                                const spotted_by = e.features[0].properties.spotted_by;
                                const spotted_username = e.features[0].properties.spotted_username;
                                const spot_date = e.features[0].properties.spot_date;
                                const description = e.features[0].properties.description;
                                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                                }
                                // this.openSpotDetail(id,false);
                                this.$refs.spotDetails.openAdminSpotDetail(id, false);
                            });
                        this.map.on('mouseenter', 'clusters', () => {
                            this.map.getCanvas().style.cursor = 'pointer';
                        });
                        this.map.on('mouseleave', 'clusters', () => {
                            this.map.getCanvas().style.cursor = '';
                        });
                    // }
                    this.mapLoaded = true;
                })
                const logo = document.querySelector('.mapboxgl-ctrl-logo');
                if (logo) {
                    logo.remove();
                }
            },
            openAdminSpotDetail(id,event) {
                this.$refs.spotDetails.openAdminSpotDetail(id,true)
            },
            openFilters() {
                this.$root.$emit("bv::show::modal", "modal-map-filters");
                // this.$refs.filterComponent.getFiltersData();
            },
            openSiderBar() {
                this.$root.$emit("bv::toggle::collapse", "sidebar-no-header");
            },
            applyFilter(params) {
                let data = {
                    spot_id: params.spotId,
                    date_from: params.dateFrom,
                    date_to: params.dateTo,
                    questions: params.questions,
                    categories: params.categories,
                    locations: params.locations,
                    spotTypes: params.spotTypes,
                    surveys: params.surveys,
                    selectedQuestions: params.selectedQuestions,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    countRangeFilter: params.countRangeFilter,
                    countRangeValues: params.countRangeValues,
                    project_id: this.selectedProjectId
                }
                API.getSpotsByAdminFilters(data,
                    data => {
                        if(data.status === 200) {
                            this.$snotify.success(data.message);
                            this.data2.features = [];
                            this.mapFeatures = [];
                            this.totalSpots = data.total_spots;
                            this.$refs.sidebar.assignQuestions(data.countableQuestions);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                            this.$refs.filterComponent.changeSliderValue(data.range);
                            const spot_dImage = data.default_image;
                            data.data.forEach(data => {
                                let exists = this.mapFeatures.find(p => p.properties.id == data.id)
                                if (!exists) {
                                    const d1 = {
                                        type: "Feature",
                                        properties: {
                                            id: data.id,
                                            title: (data.spot_type) ? data.spot_type.name : '',
                                            // spot_id: data.spot_type_id,
                                            spot_id: data.spot_id,
                                            description: (data.location) ? data.location.name : '',
                                            spotted_by: (data.spotter_user && data.spotter_user.name) ? data.spotter_user.name : '',
                                            spotted_username: (data.spotter_user && data.spotter_user.user_name) ? data.spotter_user.user_name : '',
                                            spot_date: this.formatDate(data.spot_date),
                                            iconUrl: (data.primary_image) ? this.$server_url + data.primary_image : spot_dImage,
                                            markerColor: (data.single_question && data.single_question.category) ? data.single_question.category.hash_color : 'Red'
                                        },
                                        geometry: {
                                            type: "Point",
                                            coordinates: [data.longitude, data.latitude]
                                        }
                                    }
                                    this.mapFeatures.push(d1);
                                }
                            })
                            let categoryColors = [];

                            this.mapFeatures.forEach((feature) => {
                                // Collect all category colors
                                categoryColors.push(feature.properties.markerColor || '');
                            });
                            // Set the GeoJSON source data with individual colors for each feature
                            const featuresWithColors = this.mapFeatures.map((feature, index) => {
                                return {
                                    ...feature,
                                    properties: {
                                        ...feature.properties,
                                        'icon-color': categoryColors[index] || '',
                                        'icon-halo-color': categoryColors[index] || ''
                                    }
                                };
                            });

                            this.map.getSource('earthquakes').setData({
                                type: 'FeatureCollection',
                                features: featuresWithColors
                            });
                            // this.data2 = {
                            //     "type": "FeatureCollection",
                            //     "crs": {
                            //         "type": "name",
                            //         "properties": {
                            //             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            //         }
                            //     },
                            //     "features": this.mapFeatures
                            // }
                            // this.map.getSource("earthquakes").setData(this.data2);
                        } else if(data.status === 500) {
                            this.$snotify.error(data.message);
                            this.$refs.filterComponent.StopFilterLoading();
                            this.$refs.filterComponent.StopResetLoading();
                        }
                    },
                    err => {
                        console.log(err);
                        this.$refs.filterComponent.StopFilterLoading();
                        this.$refs.filterComponent.StopResetLoading();
                    }
                );
            },
            openClusterSpotDetail(aFeatures) {
                this.$EventBus.$emit('showAdminClusterSpots');
            },
            formatDate(date) {
                return moment(String(date)).format('MM-DD-YYYY HH:mm')
            },
            saveMapStyle(value) {
                this.map.remove();
                this.default_map = 'mapbox://styles/mapbox/' + value;
                this.getClientAdminSpots();
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.appImage = this.$server_url + data.data.stock_image;
                        this.adminMapStyle = data.data.default_map;
                        this.default_map = data.data.default_map;
                        this.default_map_zoom = data.data.default_map_zoom;

                        this.default_latitude = (data.data.latitude) ? data.data.latitude : 39.452717;
                        this.default_longitude = (data.data.longitude) ? data.data.longitude : -123.813866;

                    },
                    err => {
                    }
                )
            },
            getNames() {
                const savedProjectId = localStorage.getItem("selected_project_id"); // Retrieve last selected project ID

                this.selectedProjectId = savedProjectId ? JSON.parse(savedProjectId) : null; // Set active project

                const data = {
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                    page: this.currentPage,
                    perPage: this.perPage,
                };

                this.loading = true;

                API.getProjectName(
                    data,
                    (response) => {
                    this.loading = false;

                    // Append new projects to the projectOptions list
                    const newProjects = response.data.map((project) => ({
                        id: project.id,
                        name: project.project_name,
                        default_dashboard: project.default_dashboard,
                    }));
                    this.projectOptions = [...this.projectOptions, ...newProjects];

                    // Add the new projects to the displayed list
                    this.displayedProjects = [...this.displayedProjects, ...newProjects];

                    // Update currentPage for the next load
                    this.currentPage++;

                    // Set the default selected project
                    if (!this.selectedProjectId && this.projectOptions.length > 0) {
                        const defaultProject = this.projectOptions.find(
                        (project) => project.default_dashboard == 1
                        );
                        this.selectedProjectId = defaultProject
                        ? defaultProject.id
                        : "select";
                    }
                    },
                    (err) => {
                    this.loading = false;
                    console.error("Error fetching project names:", err);
                    }
                );
            },
            onProjectClick(id) {
                if (this.selectedProjectId !== id) {
                    this.selectedProjectId = id;
                    localStorage.setItem("selected_project_id", JSON.stringify(id));
                    this.map.remove();
                    // If id is null or undefined, fetch all spots
                    if (id) {
                        this.getClientProjectSpots(id);
                    } else {
                        this.getClientAdminSpots();
                    }

                    this.$store.dispatch('updateProjectID', id);
                }
            },
        }
    };
</script>
<style scoped lang="scss">
   #coordinates {
        position: absolute !important;
        bottom: 10px !important;
    }
    .basemap {
        width: 100%;
        height: 100%;
    }

    .mapboxgl-canvas {
        width: 1200px;
        height: 689.15px;
        position: static !important;
    }

    .mapboxgl-canvas-container {
        position: static !important;
        width: 1200px !important;
    }

    #menu {
        display: flex;
        position: absolute;
        background: #efefef;
        padding: 10px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
        align-items: center;
    }

    #menu label {
        margin-bottom: 0px;
    }
    .google-map-design {
        min-height: calc(100vh - 184px);
        height: 100%;
    }
    .google-map-design #mapContainer {
        height: calc(100vh - 285px);
    }

    /* .google-map-design #mapContainer {
        min-height: calc(100vh - 254px);
        height: 100%;
    } */
    .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }
    @media (max-width: 767px) {
        .map-btns button:nth-child(1) {
            margin-right: 0px !important;
        }
    }
    @media (max-width: 705px) {
        #menu {
            width: 80%;
            flex-wrap: wrap;
        }
        .map-btns button:nth-child(1) {
            margin-left: 0px !important;
        }
    }
    @media (max-width: 500px) {
        #menu {
            width: 73%;
            flex-wrap: wrap;
        }
    }
    @media (max-width: 450px) {
        .m-ml {
            margin-left: 0px !important;
        }
    }
    @media (max-width: 375px) {
        .map-label {
            font-size: 13px;
        }
        .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    @media (max-width: 341px) {
        .map-btns button:nth-child(2) {
            margin-top: 5px;
            margin-left: 0px !important;
        }
        .map-btns button:nth-child(1) {
            margin-right: 5px !important;
        }
    }
    .b-sidebar.b-sidebar-right {
        margin-top: 105px !important;
    }
    .sidebar-counter #sidebar-no-header {
        margin-top: 105px !important;
    }
    .new-ml {
        margin-left: 10px !important;
        color: #fff !important;
    }
    .variant-orange {
        color: #fff !important;
        background-color: #fa6400;
        border-color: #fa6400;
    }

</style>
